import request, { MethodType } from "../../../utils/apis";

const API_PREFIX = "/vp";
const PLANNING_PREFIX = process.env.REACT_APP_API_PREFIX;
const INVOICE_BILL_API_URL = process.env.REACT_APP_API_INVOICE_BILL;
const PRICE_REVIEW_URL = process.env.REACT_APP_API_PRICE_REVIEW;

const SUPPLIER_API_BASE_URL = process.env.REACT_APP_API_SUPPLIER_BASE_URL;
const PROMOTION_SERVICE_EVENTS_URL =
  process.env.REACT_APP_API_PROMOTION_SERVICE;
const apiUrl = (url, noPrefix = false) => {
  return noPrefix ? PLANNING_PREFIX + url : PLANNING_PREFIX + API_PREFIX + url;
};
const SupplierApiUrl = (url, noPrefix = true) => {
  return noPrefix
    ? SUPPLIER_API_BASE_URL + url
    : SUPPLIER_API_BASE_URL + API_PREFIX + url;
};
const REACT_APP_API_SUPPLIER_SERVICE =
  process.env.REACT_APP_API_SUPPLIER_SERVICE;
const loginUrl = SUPPLIER_API_BASE_URL + "/web/login";
// const authUrl = apiUrl('/auth');
const getInvoicesUrl = INVOICE_BILL_API_URL;
const getInvoiceDetailsUrl = apiUrl("/get_invoice_detail");
const getVendorOrderUrl = SupplierApiUrl("/orders");
const getVendorOrderDetailUrl = apiUrl("/get_order_detail");
const updateVendorOrderUrl = apiUrl("/update_order");
const newCancelVendorOrderUrl = SupplierApiUrl("/orders/cancel");
const sendUploadShipmentsURL = SUPPLIER_API_BASE_URL + "/shipments";
const getSalesFiguresURL = apiUrl("/get_sales_figures");
const updateExportStatusURL = apiUrl("/update_order_exported_status");
const importProductsURL = apiUrl("/import_products_with_csv");
// const newProductsLaunchURL = apiUrl('/new_products');
const newProductsLaunchURL = SupplierApiUrl("/products");
const newProductInfoUpdateURL = SupplierApiUrl("/products/content");
const newProductStatusUpdateURL = SupplierApiUrl("/products/status");
const newPriceSaveURL = SupplierApiUrl("/products/price_update");
const productInfoUpdateURL = apiUrl("/update_product_info");
const getVendorSkuURL = apiUrl("/get_sku");

const createVendorProductURL = apiUrl("/create_product");
const updateVendorProductURL = apiUrl("/update_product");
const updateVendorProductStatusURL = apiUrl("/update_products_status");
const getCategoryURL = apiUrl("/category");
const getNewCategoryURL = SupplierApiUrl("/new_categories");
/* const getVendorProductsListURL = apiUrl('/get_products_list');
const getVendorProductsDetailsURL = apiUrl('/get_product_details'); */
const getVendorProductsListURL = SUPPLIER_API_BASE_URL + "/products";
const getVendorProductsDetailsURL =
  SUPPLIER_API_BASE_URL + "/get_product_details";
const getVendorAccountDetailsURL = apiUrl("/get_account_details");
const updateVendorAccountDetailsURL = apiUrl("/update_account_details");
const getCourierListURL = apiUrl("/get_courier_list");
const getGraphOrderDataURL = SupplierApiUrl("/orders/get_order_count_by_period");
const getGraphOrderAmountDataURL = SupplierApiUrl("/orders/get_order_amount_by_period");

export class VendorApiService {
  //Promotion Service
  getPromotionServiceEvents(vendor_id, params) {
    const serviceUrl =
      PROMOTION_SERVICE_EVENTS_URL + `/supplier/${vendor_id}/events?${params}`;
    return request.remoteUrl(
      serviceUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  //Promotion Service Event Details
  getPromotionServiceEventDetails(vendor_id, event_id) {
    const serviceUrl =
      PROMOTION_SERVICE_EVENTS_URL + `/supplier/${vendor_id}/event/${event_id}`;
    return request.remoteUrl(
      serviceUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  //Promotion Service submit Sku for joining event
  submitPromotionServiceSkus(vendor_id, event_id, params) {
    const serviceUrl =
      PROMOTION_SERVICE_EVENTS_URL +
      `/supplier/${vendor_id}/event/${event_id}/skus`;
    return request.remoteUrl(serviceUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  //Price Review
  getPriceReviewPeriod(vendor_id) {
    const serviceUrl =
      PRICE_REVIEW_URL + "/vendor/" + vendor_id + "/price_review";
    return request.remoteUrl(
      serviceUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  savePriceNew(skuList) {
    const serviceUrl = newPriceSaveURL;
    return request.remoteUrl(serviceUrl, skuList, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  // savePriceReview(vendor_id, skuList) {
  //   const serviceUrl = PRICE_REVIEW_URL + '/vendor/' + vendor_id + '/price_update';
  //   return request.remoteUrl(serviceUrl, skuList, {
  //     method: MethodType.POST,
  //     headers: { 'Content-Type': 'application/json' }
  //   });
  // }

  getPriceReviewUpdate(vendor_id) {
    const serviceUrl =
      PRICE_REVIEW_URL + "/vendor/" + vendor_id + "/price_update";
    return request.remoteUrl(
      serviceUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getSkuPriceReview(vendor_id, vendorSKU) {
    //`/price_update?skus=${vendorSKU}`
    const serviceUrl =
      PRICE_REVIEW_URL +
      "/vendor/" +
      vendor_id +
      "/price_update?skus=" +
      vendorSKU;
    return request.remoteUrl(
      serviceUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  userLogin(params) {
    return request.remoteUrl(loginUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  //   authLogin(params) {
  //     return request.remoteUrl(authUrl, params, {
  //       method: MethodType.POST,
  //       headers: { 'Content-Type': 'application/json' }
  //     });
  //   }

  getInvoices(params) {
    return request.remoteUrl(
      getInvoicesUrl.replace("V1", params.vendor_id),
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getInvoiceDetails(params) {
    return request.remoteUrl(getInvoiceDetailsUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  getVendorOrders(params) {
    return request.remoteGetUrl(getVendorOrderUrl, params, {
      method: MethodType.GET,
      headers: { "Content-Type": "application/json" },
    });
  }

  getVendorOrdersDetail(params) {
    return request.remoteUrl(getVendorOrderDetailUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  newCancelVendorOrders(params) {
    return request.remoteUrl(newCancelVendorOrderUrl, params, {
      method: MethodType.PUT,
      headers: { "Content-Type": "application/json" },
    });
  }

  updateVendorOrders(params) {
    return request.remoteUrl(updateVendorOrderUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  sendUploadShipments(params) {
    return request.remoteUrl(sendUploadShipmentsURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  getSalesFigures(params) {
    return request.remoteUrl(getSalesFiguresURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  updateExportStatus(params) {
    return request.remoteUrl(updateExportStatusURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  getSupplierShipments(params) {
    const shipmentUrl =
      SUPPLIER_API_BASE_URL + `/shipments?order_ids=${params}`;
    return request.remoteUrl(
      shipmentUrl,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  importProducts(params) {
    return request.remoteUrl(importProductsURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  getVendorSku(params) {
    return request.remoteUrl(getVendorSkuURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  createVendorProduct(params) {
    return request.remoteUrl(createVendorProductURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  updateVendorProduct(params) {
    return request.remoteUrl(updateVendorProductURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }
  // return getCat.remoteUrl(getCategoryURL);
  getProductCategory(params) {
    //return getCat.remoteUrl(getCategoryURL);
    return request.remoteUrl(
      getCategoryURL,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }
  getNewProductCategory(params) {
    //return getCat.remoteUrl(getCategoryURL);
    return request.remoteUrl(
      getNewCategoryURL,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }
  getVendorProductsList(params) {
    //console.log("getVendorProductsList params", params);
    var serviceURLString =
      getVendorProductsListURL +
      "?page_no=" +
      params.page_no +
      "&limit=" +
      params.limit;

    if (params.sku) serviceURLString = serviceURLString + "&skus=" + params.sku;
    if (params.parent_sku)
      serviceURLString = serviceURLString + "&parent_skus=" + params.parent_sku;
    if (params.name)
      serviceURLString = serviceURLString + "&name=" + params.name;
    if (params.ean_code)
      serviceURLString = serviceURLString + "&ean_code=" + params.ean_code;
    if (params.start_product_id)
      serviceURLString =
        serviceURLString + "&start_product_id=" + params.start_product_id;
    if (params.end_product_id)
      serviceURLString =
        serviceURLString + "&end_product_id=" + params.end_product_id;
    if (params.status || params.status === 0)
      serviceURLString = serviceURLString + "&status=" + params.status;
    if (params.approval || params.approval === 0)
      serviceURLString = serviceURLString + "&approval=" + params.approval;
    if (params.tff) {
      serviceURLString = serviceURLString + "&tff=" + params.tff;
    }
    return request.remoteUrl(
      serviceURLString,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getVendorProductsDetails(params) {
    return request.remoteUrl(getVendorProductsDetailsURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  updateVendorProductStatus(params) {
    return request.remoteUrl(updateVendorProductStatusURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  createOrderShipments(params) {
    const shipmentUrl = SUPPLIER_API_BASE_URL + `/shipments`;
    return request.remoteUrl(shipmentUrl, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  deleteOrderShipment(
    increment_id,
    tracking_number,
    realtime_shipment,
    carrier
  ) {
    const shipmentUrl =
      SUPPLIER_API_BASE_URL +
      `/shipments?order_id=${increment_id}&tracking_number=${tracking_number}&realtime_shipment=${realtime_shipment}&carrier=${carrier}`;
    return request.remoteUrl(
      shipmentUrl,
      {},
      {
        method: MethodType.DELETE,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getVendorAccountDetails(params) {
    return request.remoteUrl(getVendorAccountDetailsURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }
  updateVendorAccountDetails(params) {
    return request.remoteUrl(updateVendorAccountDetailsURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }

  getCourierListApi(params) {
    return request.remoteUrl(
      getCourierListURL,
      {},
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getGraphOrderData(params) {
    return request.remoteUrl(`${getGraphOrderDataURL}?purchase_date_from=${encodeURIComponent(params.purchase_date_from)}&purchase_date_to=${encodeURIComponent(params.purchase_date_to)}`, {}, {
      method: MethodType.GET,
      headers: { "Content-Type": "application/json" },
    });
  }

  getGraphOrderAmountData(params) {
    return request.remoteUrl(`${getGraphOrderAmountDataURL}?purchase_date_from=${encodeURIComponent(params.purchase_date_from)}&purchase_date_to=${encodeURIComponent(params.purchase_date_to)}`, {}, {
      method: MethodType.GET,
      headers: { "Content-Type": "application/json" },
    });
  }

  getInvoiceDownloadFile(url) {
    return request.remoteUrl(
      url,
      {},
      {
        method: MethodType.GET,
        responseType: "blob",
      }
    );
  }

  newProductsLaunch(params, tff) {
    if (tff) {
      return request.remoteUrl(newProductsLaunchURL + "?tff=" + tff, params, {
        method: MethodType.POST,
        headers: { "Content-Type": "application/json" },
      });
    } else {
      return request.remoteUrl(newProductsLaunchURL, params, {
        method: MethodType.POST,
        headers: { "Content-Type": "application/json" },
      });
    }
  }
  GetSuggestCatergoryTask(params) {
    const flag = true;
    if (flag) {
      return request.remoteGetUrl(
        SupplierApiUrl("/suggest_category_task"),
        {
          task_id: params.task_id,
          public_url: params.public_url,
        },
        {
          method: MethodType.GET,
        }
      );
    } else {
      return request.remoteGetUrl(
        SupplierApiUrl("/suggest_category_task"),
        {
          task_id: "4404da47-01f9-4b0b-8d9d-0df698cde620",
          public_url:
            "https://storage.googleapis.com/dsz-product-taxonomy-results/4404da47-01f9-4b0b-8d9d-0df698cde620-1-output.jsonl?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-84qdj%40airoxy-identity-dev.iam.gserviceaccount.com%2F20240813%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240813T072217Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=64eaf89fc783a9a39cdf73cd4e321ac7d3fa1646b201b6e53b43891fde47c1ad072fd6db2d1c5289143dd86a789ffaa7b10017aef06144fef9c74a7c7dbda1e4f4410743b88e337384b9934c15a0db09a43967afaf7bdd612ca8c6a3273763597f9090c2d72144b5366a8f42759e771838e7dddac933003372acb219457bbbc05e9f4041426febf564e5661e378bf968d0950f2b94a0d5fc3a86b644209dc0dede4b6c205208b8c36f3e4a91f20f159d61dc511ecd81bca3c4386cea600eac50c63e2f6c194733d28898a755812e0cdc65d36be7003209b36b091d62d8485f73f4f4c37b79a4b203c53387945535cc4171608445fea2930ae0df9ee59e2f0f88",
        },
        {
          method: MethodType.GET,
        }
      );
    }
    return request.remoteUrl(
      SupplierApiUrl(
        "/suggest_category_task?task_id=" +
          params.task_id +
          "&public_url=" +
          params.public_url
      ),
      {
        task_id: params.task_id,
        public_url: params.public_url,
      },
      {
        method: MethodType.GET,
        headers: { "Content-Type": "application/json" },
      }
    );
  }
  PostSuggestCatergoryTask(params, cancelToken) {
    return request.remotePostUrl(
      SupplierApiUrl("/suggest_category_task"),
      params,
      {
        method: MethodType.POST,
        headers: { "Content-Type": "application/json" },
        cancelToken: cancelToken,
      }
    );
  }

  putProductInfo(params, tff) {
    if (tff) {
      return request.remoteUrl(
        newProductInfoUpdateURL + "?tff=" + tff,
        params,
        {
          method: MethodType.PUT,
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      return request.remoteUrl(newProductInfoUpdateURL, params, {
        method: MethodType.PUT,
        headers: { "Content-Type": "application/json" },
      });
    }
  }

  putProductStatus(params) {
    return request.remoteUrl(newProductStatusUpdateURL, params, {
      method: MethodType.PUT,
      headers: { "Content-Type": "application/json" },
    });
  }

  productInfoUpdate(params) {
    return request.remoteUrl(productInfoUpdateURL, params, {
      method: MethodType.POST,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export const VendorServiceObj = new VendorApiService();
