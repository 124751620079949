import {
  FETCH_SALES_FIGURES,
  CLEAR_SALES_FIGURES,
  FETCH_GRAPH_ORDER
} from './DashboardConstants';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../../reducers/asyncReducer';

import { signOutUser } from '../../auth/authActions';

import { VendorServiceObj } from '../service/api.service';

export function fetchSalesFigures(vendorID) {
  let pageParams = {
    vendor_id: vendorID
  };

  //console.log('pageParams', pageParams);

  return async function (dispatch) {
    dispatch(asyncActionStart());
    dispatch(clearSalesFigures());
    VendorServiceObj.getSalesFigures(pageParams)
      .then(function (response) {
        // console.log('fetchSalesFigures Response', response);
        if (response.res) {
          const creditAmount = response.res.credit_amount;
          const lifeTimeSales = response.res.life_time_sales;
          const averageOrders = response.res.average_orders;
          const totalProducts = response.res.total_product;

          dispatch({
            type: FETCH_SALES_FIGURES,
            payload: {
              creditAmount,
              lifeTimeSales,
              averageOrders,
              totalProducts
            }
          });
        } else {
          //Redirect to login
          dispatch(signOutUser());
          dispatch(asyncActionError('error'));
        }
        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        //  console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function fetchGraphOrderData(vendorID, periodValue, activeTab) {
  //Calculate periods

  const d = new Date();
  const monthNumber = d.getMonth() + 1;
  let periodFrom = '';
  let periodTo = '';

  switch (periodValue) {
    case '24h':
      periodFrom =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 00:00:00';
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
      break;
    case '7d':
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
      d.setDate(d.getDate() - 7);
      periodFrom =
        d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + ' 00:00:00';
      break;
    case '1m':
      periodFrom = d.getFullYear() + '/' + monthNumber + '/01 00:00:00';
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
      break;
    case '1y':
      periodFrom = d.getFullYear() + '/01/01 00:00:00';
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
      break;
    case '2y':
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
      d.setFullYear(d.getFullYear() - 1);
      periodFrom = d.getFullYear() + '/01/01 00:00:00';
      break;
    default:
      periodFrom =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 00:00:00';
      periodTo =
        d.getFullYear() + '/' + monthNumber + '/' + d.getDate() + ' 23:59:59';
  }

  let pageParams = {
    purchase_date_from: periodFrom,
    purchase_date_to: periodTo,
  };

  //console.log('pageParams', pageParams);

  return async function (dispatch) {
    dispatch(asyncActionStart());

    if (activeTab === '1') {
      VendorServiceObj.getGraphOrderData(pageParams)
        .then(function (response) {
          // console.log('fetchGraphOrderData Response', response);
          if (response.res) {
            var yAxisValues = [];
            var xAxisValues = [];

            if (response.res.success && response.res.data) {
              const graphData = response.res.data.orders;
              //console.log('graphData', graphData.length);

              for (let i = 0; i < graphData.length; i++) {
                //  console.log('item', graphData[i].period);
                yAxisValues.push(graphData[i].value);
                xAxisValues.push(graphData[i].period);
              }
            }
            dispatch({
              type: FETCH_GRAPH_ORDER,
              payload: {
                xAxisValues,
                yAxisValues,
                periodValue
              }
            });
          } else {
            //Redirect to login
            // dispatch(signOutUser());
            dispatch(asyncActionError('error'));
          }
          dispatch(asyncActionFinish());
        })
        .catch(function (error) {
          //  console.error(error.data);
          dispatch(asyncActionError(error));
        });
    } else if (activeTab === '2') {
      VendorServiceObj.getGraphOrderAmountData(pageParams)
        .then(function (response) {
         // console.log('getGraphOrderAmountData Response', response);
          if (response.res) {
            var yAxisValues = [];
            var xAxisValues = [];

            if (response.res.success && response.res.data) {
              const graphData = response.res.data.orders;
             // console.log('graphData', graphData.length);

              for (let i = 0; i < graphData.length; i++) {
                //  console.log('item', graphData[i].period);
                yAxisValues.push(graphData[i].value);
                xAxisValues.push(graphData[i].period);
              }
            }
            dispatch({
              type: FETCH_GRAPH_ORDER,
              payload: {
                xAxisValues,
                yAxisValues,
                periodValue
              }
            });
          } else {
            //Redirect to login
            // dispatch(signOutUser());
            dispatch(asyncActionError('error'));
          }
          dispatch(asyncActionFinish());
        })
        .catch(function (error) {
          //  console.error(error.data);
          dispatch(asyncActionError(error));
        });
    }
  };
}

export function clearSalesFigures() {
  return {
    type: CLEAR_SALES_FIGURES
  };
}
